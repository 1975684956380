import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as styles from "./TextMedia.module.scss";
import Icon from "../../svgs/single-card.svg";
import { StaticImage } from "gatsby-plugin-image";

const defaultData = [
  {
    title: "Calendar or Custom Boards",
    text: "Quickly create and edit boards using dates or custom column names. Create and name Row Groups for extra versatile planning.",
    image: (
      <StaticImage
        src='../../images/new-board.png'
        alt='Calendar or Custom Boards'
      />
    ),
  },
  {
    title: "Unlimited Card Designs",
    text: "Card Designs can easily be created to suit any process using one of our design templates or simply create your own.",
    image: (
      <StaticImage
        src='../../images/new-design.png'
        alt='Unlimited Card Designs'
      />
    ),
  },
  {
    title: "Flexible User Permissions",
    text: "Change permissions to restrict the access or editing rights for all team members. Choose who can edit or view anything from Boards to Row Groups to individual fields.",
    image: (
      <StaticImage
        src='../../images/user-permissions.png'
        alt='Flexible User Permissions'
      />
    ),
  },
  {
    title: "Import Data to Boards",
    text: "Quickly import cards straight to a Board from an Excel spreadsheet. Simply download the Board template, populate the fields and upload.",
    image: (
      <StaticImage src='../../images/import.png' alt='Import Data to Boards' />
    ),
  },
];

const TextMedia = ({ title, data = defaultData }) => {
  const blocks = data.map((el, i) => {
    const even = i % 2 === 0;

    return (
      <div key={"textMediaBlock-" + i} className={styles.block}>
        <Row className='align-items-md-center'>
          <Col lg={{ order: even ? 0 : 1 }}>
            <div
              className={styles.image}
              data-sal={`slide-${even ? "right" : "left"}`}
              data-sal-duration='500'
              data-sal-delay='0'
              data-sal-easing='ease'
            >
              {el.image}
            </div>
          </Col>
          <Col lg>
            <div className={styles.text}>
              <h2>{el.title}</h2>
              <p>{el.text}</p>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <section className={styles.root}>
      <Icon className={`${styles.icon} ${styles.icon1}`} />
      <Icon className={`${styles.icon} ${styles.icon2}`} />
      <Icon className={`${styles.icon} ${styles.icon3}`} />
      <Container>
        {title && (
          <h2 className='title text-center italic'>
            {title}
            {/* ...with productivity <span>boosting</span> features... */}
          </h2>
        )}

        {blocks}
      </Container>
    </section>
  );
};

export default TextMedia;
