import React from "react";
import Layout from "../components/Layout/Layout";
import { Helmet } from "react-helmet";
import Pricing2 from "../components/Pricing2/Pricing2";
import TextMedia from "../components/TextMedia/TextMedia";
import Features from "../components/Features/Features";
import Service from "../components/Service/Service";

const title = {
  display: "inline-block",
  paddingTop: "3rem",
};

const Text = () => {
  return (
    <>
      <h1>Fair Pricing</h1>
      <p>
        Prices are based on the number of users, not the number of features, so
        whether a team of 2 or 100 you get the same great product:
      </p>
      <p>
        Unlimited T Cards, Unlimited Card Designs, 5 Custom Boards, 1gb
        Attachment Storage and much more.
      </p>
    </>
  );
};

const PricingPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>T Cards Online | Pricing</title>
      </Helmet>
      <Pricing2 additional showAdvanced text={<Text />} />
      <TextMedia
        title={
          <span style={title}>
            Packed with productivity <em>boosting</em> features...
          </span>
        }
      />
      <Features />
      <Service />
    </Layout>
  );
};

export default PricingPage;
