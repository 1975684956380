import { Link } from "gatsby";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import * as styles from "./Service.module.scss";

const serviceContent = [
  {
    title: "FREE Bespoke Setup",
    body: `We understand that our clients don't always have the time to set
  up and learn a brand new system so we can do that for you
  completely FREE OF CHARGE. Get truly custom Boards and T Cards
  to suit your specific needs alongside ongoing assistance at zero
  extra cost.`,
    img: "http://placehold.it/800x600",
  },
  {
    title: "Personal Client Support",
    body: `When you contact us you'll be speaking directly to a member of
    our team and never a call centre. Client satisfaction is our top
    priority so you can rest assured that whatever your query we'll
    be there to assist you personally.`,
    img: "http://placehold.it/800x600",
  },
  {
    title: "Always Evolving, Always Listening",
    body: `We're constantly adding new features to T Cards Online based on
  our clients' needs so if there's a feature your business needs
  which we're missing, contact us as it could be coming soon.`,
    img: "http://placehold.it/800x600",
  },
];

const Service = (props) => {
  return (
    <section className={styles.root}>
      <Container>
        <Row className='align-items-center'>
          <Col lg className={styles.left}>
            {/* {serviceContent.map((item, i) => (
              <img
                key={"service-img-" + i}
                className='img-fluid'
                src={item.img}
              />
            ))} */}
            <h2 className='title'>
              Packaged with
              <br />
              50+ Years of Planning Experience
            </h2>
            <p>
              T Cards Online is built and maintained by T Cards Direct; A family
              business with over 50 years experience designing, manufacturing
              and supplying T Cards and T Card Systems for a wide range of{" "}
              <Link to='/construction'>industries</Link>.
            </p>
            <p>
              A subscription to T Cards Online includes full access to this
              experience offering support and advice to improve your processes.
            </p>

            <Button
              variant='primary'
              size='lg'
              href='https://app.tcardsonline.co.uk/register'
            >
              Sign up for a free trial now
            </Button>
          </Col>
          <Col lg>
            {serviceContent.map((item, i) => (
              <div key={"service-content-" + i} className={styles.box}>
                <h4>{item.title}</h4>
                <p>{item.body}</p>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Service;
